<template>
<Loading v-show="show" />

<div class="breadcrumb-banner-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="breadcrumb-text">
                    <h1 class="text-left">{{Activity}}</h1>
                    <div class="breadcrumb-bar">
                        <ul class="breadcrumb text-left">
                            <li>
                                <router-link to="/">{{home}}</router-link>
                            </li>
                            <li> {{Activity}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="latest-area section-padding" style="padding: 50px 0 60px">
    <div class="container">
        <div class="row">
            <div v-for="event in Events" :key="event.postId" class="col-lg-4 col-md-6 col-12 events_top">
                <div class="single-event-item">
                    <div class="single-event-image">
                        <a href="javascript:void(0)">
                            <img v-bind:src="'https://api2.yg-u.net/images/post/' + event.postImage" />
                            <span><span>{{ event.day1 }}</span>{{ event.nT_MONTH1 }}</span>
                        </a>
                    </div>
                    <div class="single-event-text">
                        <h6>
                            <a href="javascript:void(0)" style="line-height: 2; text-align: justify">{{ event.postTitle }}
                            </a>
                        </h6>
                        <div class="single-item-comment-view">
                            <span><i class="fa fa-clock-o"></i>{{ event.time1 }}</span>
                            <span><i class="fa fa-map-pin"></i>{{ event.txtAdress1 }} </span>
                        </div>
                        <p style="text-align: justify">
                            {{ event.postSubTitle }}
                        </p>
                        <router-link class="button-default" :to="{
                          name: 'Event-with-id-And-Type',
                          params: {
                            type: $route.params.type,
                            id: event.postID,
                          },
                        }">
                            {{Detials}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Loading from './Loading'

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
    components:{
Loading
  },
    data() {
        return {
            Events: [],
            show:true,
            //translate
            ArTranslat: ArJson,
            EnTranslat: EnJson,
            translate: "",
            home: "",
            Activity: "",
            Detials: '',
        };
    },
    mounted() {

        window.scroll(0, 0);
        var self = this;
    
        if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      })
        .then(function (response) {
          self.lang = response.data[0]["LangId"];
          localStorage.setItem("lang", response.data[0]["LangId"]);
          if (response.data[0]["LangId"] == "Ar") {
            document.getElementById("langfilewebsites")?.remove();
            self.getApis();
          } else {
            self.translate = self.EnTranslat;
            let link1 = document.createElement("link");
            link1.setAttribute("rel", "stylesheet");
            link1.id = "langfilewebsites";
            link1.setAttribute("href", "../../../style-ltr.css");
            document.head.appendChild(link1);
            self.getApis();
          }

             if (localStorage.getItem("lang") == "Ar") {
            self.translate = self.ArTranslat;
        } else {
            self.translate = self.EnTranslat;
        }
        self.Activity = self.translate[0]["Home"]["Activity"];
        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.Detials = self.translate[0]["Home"]["Detials"];

        })
        .catch(function (response) {
          console.log("error", response);
        });
    } else {
      if (localStorage.getItem("lang") == "Ar") {
        document.getElementById("langfilewebsites")?.remove();
        self.getApis();
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "../../../style-ltr.css");
        document.head.appendChild(link1);
        self.getApis();
      }
    }
    },
    methods: {
        getApis() {
            var self = this;
            var bodyFormData1 = new FormData();
            bodyFormData1.append("check", "getPost");
            bodyFormData1.append("Lang", localStorage.getItem('lang'));
            bodyFormData1.append("FbrnId", "U");
            bodyFormData1.append("Type", this.$route.params.type);
            bodyFormData1.append("ParentId", "NTROOT0");
            bodyFormData1.append("PostName", "");
            bodyFormData1.append("Pno", "-1");
            axios({
                    method: "post",
                    url: "https://api2.yg-u.net/our_team/getPost",
                    data: bodyFormData1,
                })
                .then(function (response) {
                    self.Events = response.data;
                    document.getElementById("VueMainJsNewTouch")?.remove();
                    let recaptchaScript = document.createElement("script");
                    recaptchaScript.setAttribute("src", "/js/main.js");
                    recaptchaScript.id = "VueMainJsNewTouch";
                    document.head.appendChild(recaptchaScript);
                    setTimeout(() => {
                      self.show = false
                      document.getElementById('YG_U').setAttribute('style','display:none !important')

                    }, 100);
                })
                .catch(function (response) {
                    console.log("error", response);
                });

                       if (localStorage.getItem("lang") == "Ar") {
            self.translate = self.ArTranslat;
        } else {
            self.translate = self.EnTranslat;
        }
        self.Activity = self.translate[0]["Home"]["Activity"];
        self.home = self.translate[0]["HeaderAndFooter"]["Home"];
        self.Detials = self.translate[0]["Home"]["Detials"];

        }
    }
};
</script>

<style scoped>
.events_top {
    margin-top: 50px;
}

.single-event-image span {
    font-size: 28px !important;
}
</style>
